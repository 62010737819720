<script>
import Layout from "../../../layouts/main";
import PageHeader from "@/components/page-header";
import Pagination from "@/components/widgets/pagination";

import Tablesaw from 'tablesaw'
import {reloadTablesaw} from "@/helpers/datatable";

import store from "@/state/store";
import moment from "moment";
import {apiUserMethods} from "@/state/helpers";
import Loader from "@/components/widgets/loader";

// 검색 기본값
const BASE_SEARCH = {
  dateField : 'created_at',
  field : 'fixedcrypt:phone+name+email',
  keyword : '',
  startDate : '',
  endDate : '',
  period : 7,
  status : 'Registered',
  page : 1,
}

export default {
  components: { Layout, PageHeader, Pagination, Loader },
  data() {
    return {
      menuId: 200200,
      menuCode: "user",
      currentPage: 1,
      totalCount: 0,
      listData: [],
      isPageLoading: false,
      datepicker: {
        startDate : null,
        endDate : null,
      },
      search: BASE_SEARCH,
      // 검색키워드 항목
      fields: [
        { value: 'fixedcrypt:phone+name+email', text: '이름/휴대폰/이메일' },
        { value: 'device_detail', text: '단말기정보' },
        { value: 'device_os', text: '운영체제(OS)' },
      ],
      periods: [
        { value: 7, text: '1주일' },
        { value: 1, text: '1개월' },
        { value: 3, text: '3개월' },
        { value: 6, text: '6개월' },
        { value: 0, text: '전체' }
      ],
      states: [
        { value: 'Registered', text: '가입회원' },
        { value: 'Connected', text: '접속중(5분)' },
        { value: 'LongConnected', text: '접속중(5시간)' },
        { value: 'SignOut', text: '탈퇴회원' },
      ],
    };
  },
  created() {
    this.$changeMenu(this.menuId, this.menuCode)
  },
  mounted () {
    Tablesaw._init();
  },
  beforeRouteUpdate(to, from, next) {
    if(to.fullPath !== from.fullPath) {
      this.initSearch(to.query)
      next();
    }
  },
  methods: {
    ...apiUserMethods,

    onRoutechange() {
      this.initSearch(this.$route.query)
      this.loadData()
    },

    // 데이터 추출
    loadData() {
      this.searchDate()

      if (!moment(this.search.startDate, 'YYYY-MM-DD', true).isValid()) {
        this.search.startDate = ""
      }

      if (!moment(this.search.endDate, 'YYYY-MM-DD', true).isValid()) {
        this.search.endDate = ""
      }

      let page = this.search.page
      let type = 'users'

      // 검색 조건 결합
      let params = {
        ...this.$route.query,
        ...this.search,
        page: page
      };
      // console.log(params);

      this.isPageLoading = true
      store.dispatch("apiUsers/list", { type, params }).then((result) => {
        this.updateTableData(result.data, result.recordsFiltered)
        this.isPageLoading = false
      }, () => {
        this.isPageLoading = false
      })
    },

    // 데이터 가공
    updateTableData(data, total) {
      this.listData = data;
      this.totalCount = total;

      this.$nextTick(function () {
        // Tablesaw가 동적으로 처리되지 않아 별도 처리
        reloadTablesaw(this.$refs.tablesaw)
      })
    },

    // 라우트 변경 ( 브라우져 뒤로가기 구현 )
    changeRouter() {
      // 검색조건에 검색기간 설정
      this.searchDate()

      if (!moment(this.search.startDate, 'YYYY-MM-DD', true).isValid()) {
        this.search.startDate = "all"
      }

      if (!moment(this.search.endDate, 'YYYY-MM-DD', true).isValid()) {
        this.search.endDate = "all"
      }

      let page = this.search.page
      let params = { page: page };

      // 쿼리스트링 결합
      let query = {
        ...this.$route.query,
        ...this.search,
        ...params
      };

      // 히스토리 추가
      this.$router.push({ query })
    },

    // 페이징 처리
    changePage(page) {
      if (this.search.page != page) {
        this.currentPage = this.search.page = page
        this.changeRouter()
      }
    },

    // 검색 초기값 설정
    initSearch(routeQuery) {
      if (routeQuery.startDate != "all" && !moment(routeQuery.startDate, 'YYYY-MM-DD', true).isValid()) {
        this.datepicker.startDate = new Date(moment().add(-7, 'days').format('YYYY-MM-DD'))
      }
      if (routeQuery.endDate != "all" && !moment(routeQuery.endDate, 'YYYY-MM-DD', true).isValid()) {
        this.datepicker.endDate = new Date(moment().format('YYYY-MM-DD'))
      }

      this.search = Object.assign({}, {...BASE_SEARCH, ...routeQuery})
      this.search.page = this.search.page * 1
      this.currentPage = this.search.page

      // 라우트가 변경되면 검색기간 변경
      if (this.search.startDate) {
        this.datepicker.startDate = new Date(this.search.startDate)
      }
      if (this.search.endDate) {
        this.datepicker.endDate = new Date(this.search.endDate)
      }
    },

    // 검색 실행
    searchData() {
      this.currentPage = this.search.page = 1

      // 라우트가 변경되면 loadData 호출
      this.changeRouter()
    },

    // 검색기간 설정
    searchDate() {
      if (this.datepicker.startDate) {
        this.search.startDate = moment(this.datepicker.startDate).format('YYYY-MM-DD')
      } else {
        this.search.startDate = "all"
      }

      if (this.datepicker.endDate) {
        this.search.endDate = moment(this.datepicker.endDate).format('YYYY-MM-DD')
      } else {
        this.search.endDate = "all"
      }
    },

    // 단위별 검색기간 변경
    // 전체:0, 일주일:7, 월단위:1,3,6,9,12, 일단위:12~
    searchPeriod(period) {
      this.search.period = period
      if (period > 0) {
        this.datepicker.endDate = new Date(moment().format('YYYY-MM-DD'))
      } else {
        this.search.endDate = "all"
        this.datepicker.endDate = ""
      }
      if (period == 7 || period > 12) {
        this.datepicker.startDate = new Date(moment().add(period*(-1), 'days').format('YYYY-MM-DD'))
      } else if (period) {
        this.datepicker.startDate = new Date(moment().add(period*(-1), 'months').format('YYYY-MM-DD'))
      } else {
        this.search.startDate = "all"
        this.datepicker.startDate = ""
      }
      this.searchData()
    },

    // 현재 설정된 검색기간 스타일 변경
    variantPeriod(period) {
      if (period === 7 && !this.$route.query.period) {
        return "info"
      } else {
        return (period == this.$route.query.period) ? "info" : "outline-secondary"
      }
    },

    // 회원상태 검색
    searchStatus(status) {
      this.search.status = status
      this.searchData()
    },

    // 현재 설정된 회원상태 스타일 변경
    variantStatus(status) {
      if (this.search.status == status) {
        return "info"
      } else {
        return "outline-secondary"
      }
    },
  },
  watch: {
    $route: {
      handler: "onRoutechange",
      immediate: true,
      deep: true,
    }
  },
};
</script>

<template>
  <Layout>
    <PageHeader />
    <div class="row">
      <div class="col-lg-12">
        <div class="card min-content">
          <div class="card-body">
            <div>
              <b-form class="px-2" @submit.prevent="searchData">
                <div class="form-group row mb-2 pb-2 search-line">
                  <label class="col-form-label col-sm-2 text-nowrap">회원구분</label>
                  <div class="col-sm input-group">
                    <div class="col-sm-2 w-auto text-nowrap">
                      <b-button
                          v-for="state in states"
                          :key="state.value"
                          :variant="variantStatus(state.value)"
                          @click="searchStatus(state.value)"
                          class="spacing-10 me-1"
                      >{{ state.text }}</b-button>
                    </div>
                  </div>
                </div>
                <div class="form-group row mb-2 pb-2 search-line">
                  <label class="col-form-label col-sm-2 text-nowrap">가입기간</label>
                  <div class="col-sm input-group">
                    <div class="col-sm-2 pe-2 mb-1 search-date">
                      <date-picker
                          input-class="form-control"
                          v-model:value="datepicker.startDate"
                          value-type="date"
                      ></date-picker>
                    </div>
                    <div class="col-sm-2 pe-2 mb-1 search-date">
                      <date-picker
                          input-class="form-control"
                          v-model:value="datepicker.endDate"
                          value-type="date"
                      ></date-picker>
                    </div>
                    <div class="col-sm-2 w-auto text-nowrap">
                      <b-button
                          v-for="period in periods"
                          :key="period.value"
                          :variant="variantPeriod(period.value)"
                          @click="searchPeriod(period.value)"
                          class="spacing-10 me-1"
                      >{{ period.text }}</b-button>
                    </div>
                  </div>
                </div>
                <div class="form-group row mb-3 pb-2 search-line">
                  <label class="col-form-label col-sm-2 text-nowrap">키워드 검색</label>
                  <div class="col-sm input-group text-nowrap">
                    <b-form-select
                        for="field"
                        v-model="search.field"
                        :options="fields"
                        class="form-control w-auto"
                        style="width: 150px; flex: none"
                    ></b-form-select>
                    <b-form-input
                        for="keyword"
                        v-model="search.keyword"
                        class="form-control"
                    ></b-form-input>
                  </div>
                </div>
                <div class="row justify-content-between pb-3">
                  <div class="col">
                    <b-button type="submit" variant="primary">검색하기</b-button>
                  </div>
                  <div class="col text-end">
                    <router-link to="/users/users/create" class="btn btn-danger ms-2">등록하기</router-link>
                  </div>
                </div>
              </b-form>
              <div class="position-relative" style="min-height: 130px;">
                <table ref="tablesaw" class="tablesaw table align-middle table-nowrap table-hover" data-tablesaw-mode="columntoggle">
                  <thead class="table-light">
                  <tr>
                    <th scope="col" class="wd-60" data-tablesaw-priority="6">순번</th>
                    <th scope="col" class="wd-80 text-start" data-tablesaw-priority="persist">회원명</th>
                    <th scope="col" class="text-start" data-tablesaw-priority="persist">이메일</th>
                    <th scope="col" class="wd-90" data-tablesaw-priority="4">생년월일</th>
                    <th scope="col" class="wd-100" data-tablesaw-priority="persist">기기정보</th>
                    <th scope="col" class="wd-80" data-tablesaw-priority="3">버전정보</th>
                    <th scope="col" class="wd-100 text-end pe-3" data-tablesaw-priority="5">가입일/{{ search.status == 'SignOut' ? '탈퇴일' : '접속일' }}</th>
                  </tr>
                  </thead>
                  <tbody>
                  <tr v-for="list in listData" :key="list.uid">
                    <td>{{ list.rownum }}</td>
                    <td class="text-start">
                      <router-link :to="`/users/users/${list.uid}`">
                        <div class="ellipsis ellipsis-middle">
                          <span style="left:0;">
                            <strong>{{ list.name }}</strong>
                            <img v-if="list.social_type" :src="require(`@/assets/images/social/icon_${list.social_type.toLowerCase()}.png`)" class="align-text-top ms-1" height="16" />
                            <i class="align-middle ms-1" :class="list.connection_css" v-b-tooltip.hover :title="list.connection_text"></i>
                          </span>
                        </div>
                        <div class="ellipsis ellipsis-middle spacing-10"><span style="left:0;">{{ list.phone }}</span></div>
                      </router-link>
                    </td>
                    <td class="text-start">
                      <div>
                        <router-link :to="`/users/users/${list.uid}?tab=relations`"><b-badge variant="primary" class="font-size-11 text-white px-2 me-1" v-b-tooltip.hover title="보호자">{{ list.count_parents }}</b-badge></router-link>
                        <router-link :to="`/users/users/${list.uid}?tab=relations`"><b-badge variant="success" class="font-size-11 text-white px-2 me-1" v-b-tooltip.hover title="피보호자">{{ list.count_childs }}</b-badge></router-link>
                        <router-link :to="`/users/users/${list.uid}?tab=relations`"><b-badge variant="warning" class="font-size-11 text-white px-2 me-1" v-b-tooltip.hover title="안심존">{{ list.count_zones }}</b-badge></router-link>
                        <router-link :to="`/users/users/${list.uid}?tab=recommends`"><b-badge variant="info" class="font-size-11 text-white px-2" v-b-tooltip.hover title="추천인">{{ list.count_referral }}</b-badge></router-link>
                      </div>
                      <div class="ellipsis ellipsis-small">
                        <span style="left:0;">{{ list.email }}</span>
                      </div>
                    </td>
                    <td>
                      <div v-if="list.birthday">{{ list.birthday }}<br>({{ list.age }}세, {{ list.gender }})</div>
                      <div v-else>-</div>
                    </td>
                    <td>
                      <div v-if="list.device_os">
                        <img v-if="list.device_os == 'Android'" :src="require(`@/assets/images/social/icon_android.png`)" height="16" class="me-1" v-b-tooltip.hover title="Android" />
                        <img v-if="list.device_os == 'iOS'" :src="require(`@/assets/images/social/icon_apple.png`)" height="16" class="me-1" v-b-tooltip.hover title="Apple" />
                        <img v-if="list.device_os == 'Android'" :src="require(`@/assets/images/device/ic_state_power${ !list.is_power ? '_off' : '' }.png`)" height="16" class="me-1" v-b-tooltip.hover :title="list.is_power ? 'ON' : 'OFF'" />
                        <img :src="require(`@/assets/images/device/ic_state_gps${ !list.is_gps ? '_off' : '' }.png`)" height="16" class="me-1" v-b-tooltip.hover :title="list.is_gps ? 'ON' : 'OFF'" />
                        <img v-if="list.device_os == 'Android'" :src="require(`@/assets/images/device/ic_state_mobile${ !list.is_mobile ? '_off' : '' }.png`)" height="16" class="me-1" v-b-tooltip.hover :title="list.is_mobile ? 'ON' : 'OFF'" />
                        <img v-if="list.device_os == 'Android'" :src="require(`@/assets/images/device/ic_state_wifi${ !list.is_wifi ? '_off' : '' }.png`)" height="16" class="me-1" v-b-tooltip.hover :title="list.is_wifi ? 'ON' : 'OFF'" />
                      </div>
                      <div v-if="list.device_os" class="ellipsis ellipsis-small">
                        <span class="font-size-12" :class="{ 'text-danger': !list.last_address }">{{ list.last_address ? list.last_address : '수집 X' }}</span>
                      </div>
                      <div v-else>-</div>
                    </td>
                    <td>
                      <div>{{ list.device_model }}</div>
                      <div v-if="list.app_version && list.app_version != '-'">{{ list.app_version }} ({{ list.os_version }})</div>
                    </td>
                    <td class="text-end spacing-5">
                      <div>
                        {{ list.join_date }}
                        <i class="bx bx-plus-circle font-size-17 align-middle" style="color:#999;"></i>
                      </div>
                      <div v-if="search.status == 'SignOut'" class="text-danger">
                        {{ list.delete_date }}
                        <i class="bx font-size-17 align-middle bxs-right-arrow-circle"></i>
                      </div>
                      <div v-else-if="list.last_date" :class="{ 'text-success': list.last_logined, 'text-danger': !list.last_logined }">
                        <i v-if="list.last_device" :class="`align-middle bx bx-${ list.last_device }`"></i> {{ list.last_date }}
                        <i class="bx font-size-17 align-middle" :class="{ 'bxs-left-arrow-circle': list.last_logined, 'bxs-right-arrow-circle': !list.last_logined }"></i>
                      </div>
                      <div v-else class="pe-1 text-secondary">접속정보 없음</div>
                    </td>
                  </tr>
                  </tbody>
                </table>
                <div class="position-absolute base-list-message pt-5" v-show="!isPageLoading && listData.length === 0">조회된 내역이 없습니다.</div>
                <Loader :loading="isPageLoading" class="position-absolute base-list-loader mt-5"></Loader>
              </div>
            </div>
            <div class="row">
              <div class="col-lg-12">
                <Pagination :total="totalCount" v-model:page="currentPage" @changePage="changePage($event)"></Pagination>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>
